import CryptoJS from "crypto-js";

/**
 * Redirect to region dashbaord pathname
 *
 */
export const redirectToJoinPageRegion = (event, toForm = false) => {
  const joinPage = window.location.pathname.split("/")[1];
  if (toForm) {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/${joinPage}/form/${event}`;
  } else {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/${joinPage}/${event}`;
  }
};

/**
 * Get event API endpoint
 *
 */
export const getEventApiEndpoint = () => {
  // const eventType = window.location.pathname.split("/")[1];
  // switch (eventType) {
  //   case "regismp-id":
  //     return process.env.REACT_APP_API_MPID_ENDPOINT;

  //   case "regismp-msp":
  //     return process.env.REACT_APP_API_MPMSP_ENDPOINT;

  //   case "regisbr-id":
  //     return process.env.REACT_APP_API_BRID_ENDPOINT;

  //   case "regisbr-msp":
  //     return process.env.REACT_APP_API_BRMSP_ENDPOINT;

  //   default:
  //     return process.env.REACT_APP_API_MPID_ENDPOINT;
  //   }
  return process.env.REACT_APP_API_ENDPOINT;
};

/**
 * Removing trailing param on url
 *
 */
export const removeParamUrl = () => {
  const location = window.location;
  window.history.pushState(
    {},
    document.title,
    location.origin + location.pathname
  );
};

/**
 * Get spesific param url
 *
 * @param {string} pa - Unique key of search string on url.
 */
export const getUrlParam = (pa) => {
  let url = window.location.href.replace(/#+.*$/),
    params = url.substring(url.indexOf("?") + 1, url.length).split("&"),
    param = {};

  for (let i = 0; i < params.length; i++) {
    let pos = params[i].indexOf("="),
      key = params[i].substring(0, pos),
      val = params[i].substring(pos + 1);

    param[key] = val;
  }

  return typeof param[pa] === "undefined" ? false : param[pa];
};

/**
 *
 * @param {object} err - Error response object from axios.
 */
export const err_msg = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 500:
        return "Please Refresh Your Browser!";
      default:
        return err.response.data.message;
    }
  } else if (err.request) {
    return "Please Refresh Your Browser!";
  } else {
    return "Please Refresh Your Browser!";
  }
};

/**
 *
 * @param {string} key - Unique key for storing and identifying your data in local storage.
 * @param {*} values - Value of data you want store in local storage.
 */
export const setLsObject = (key, values) => {
  window.localStorage.setItem(key, JSON.stringify(values));
};

/**
 *
 * @param {string} key - Unique key for accessing your stored data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getLsObject = (key) => {
  return JSON.parse(window.localStorage.getItem(key));
};

/**
 *
 * @param {string} key - Unique key for storing your hash data in local storage.
 * @param {*} values - Value of data from hash endpoint you want store in local storage.
 */
export const setHash = (key, values) => {
  setLsObject("hash", {
    ...getLsObject("hash"),
    [key]: values,
  });
};

/**
 *
 * @param {string} key - Unique key for accessing your hash data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getHash = (key) => {
  return getLsObject("hash")?.[key];
};

/**
 *
 * @param {string} key - Uniue key for storing your cached data in local storage.
 * @param {*} values - Value of data from any endpoint you want to be cached.
 */
export const setCache = (key, values) => {
  setLsObject("cache", {
    ...getLsObject("cache"),
    [key]: {
      ...getLsObject("cache")[key],
      data: values,
    },
  });
};

/**
 *
 * @param {string} key - Unique key for accessing your cached data in local storage.
 * @returns {*} - Return any type of data you cached.
 */
export const getCache = (key) => {
  return getLsObject("cache")?.[key]?.data;
};

/**
 *
 * @param {string} key - Unique key for storing your PDC data in local storage.
 * @param {*} value - Value of data from PDC endpoint you want to store.
 */
export const setPdc = (key, value) => {
  setLsObject("pdc", {
    ...getLsObject("pdc"),
    [key]: value,
  });
};

/**
 *
 * @param {string} key - Unique key for accessing your PDC data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getPdc = (key) => {
  return getLsObject("pdc")?.[key]?.value;
};

/**
 *
 * @param {string} key - Reset cached data.
 */
export const resetThrottle = (key) => {
  setLsObject("cache", {
    ...getLsObject("cache"),
    [key]: {},
  });
};

/**
 *
 * @param {string} key - Unique key for identifying your data on cache.
 * @param {number} ms - Number of miliseconds that data you want to throttle.
 */
export const throttle = (key, ms) => {
  const now = new Date();
  const item = getLsObject("cache");
  const lastTime = item?.[key]?.time || 0;

  const timeDiff = now.getTime() - lastTime;

  if (timeDiff < ms) {
    return true;
  } else {
    setLsObject("cache", {
      ...getLsObject("cache"),
      [key]: {
        ...getLsObject("cache")?.[key],
        time: now.getTime(),
      },
    });

    return false;
  }
};

/**
 *
 * @param {*} payload - Payload that you want to encrypt
 * @param {string} type - Type of the payload.
 */
export const encryptPayload = (payload, type) => {
  let passphrase = "";

  if (type === "standalone") {
    passphrase = "WTXkexHGcqEnTRmjGHA5";
  } else if ((type = "dtl")) {
    passphrase = "PUTkexHGcqEnRmjAPS95";
  }

  const jsonPayload = JSON.stringify(payload);

  const ctObj = CryptoJS.AES.encrypt(jsonPayload, passphrase);
  const ctStr = ctObj.toString();

  return ctStr;
};

/**
 *
 * @param {object,array} data - list data that you want to preload
 * @param {string} prefix - prefix of image key from directus (e.g. img for img_bg)
 */
export const preload = (data, prefix = false) => {
  // prettier-ignore
  const allowedExtensions = [ "jpg", "jpeg", "png", "mp3", "mp4", "webm", "mpg", "mpeg", "mov", "ico", "gif", "svg", "bmp", "otf", "ttf" ];
  let mapping = prefix
    ? Object.fromEntries(
        Object.entries(data).filter(([key]) => key.includes(prefix))
      )
    : data;
  let images = [];

  for (let i in mapping) {
    if (mapping.hasOwnProperty(i)) {
      let fileExtension = mapping[i].split(".").pop();
      if (allowedExtensions.includes(fileExtension)) {
        images[i] = new Image();
        images[i].src = mapping[i];
      }
    }
  }
};

/**
 *
 * @param {component} lazyComponent - React component that you want to code splitting
 * @param {number} attemptsLeft - How many attemps to load the chunks if failed
 */
export const componentLoader = (lazyComponent, attemptsLeft = 3) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
};

/**
 * [EXPERIMENTAL] Send user device and other information using 3 finger touch on attached element
 *
 * @param {object} e - Event object
 */
export const sendDebug = (e) => {
  const axios = require("axios");

  const report_data = {
    embeds: [
      {
        fields: [
          {
            name: "Website Title:",
            value: document.title,
          },
          {
            name: "URL:",
            value: window.location.href,
          },
          {
            name: "Release:",
            value: window.releases,
          },
          {
            name: "User Agent:",
            value: navigator.userAgent,
          },
          {
            name: "Viewport:",
            value: window.innerWidth + " x " + window.innerHeight,
          },
          {
            name: "Token / Param:",
            value:
              window.localStorage.getItem("token") || // OTHER GAMES
              JSON.stringify(window.localStorage.getItem("param")) || // FREEFIRE
              "-",
          },
        ],
        footer: {
          text: "React Debug Trigger by Wahyu Henditya",
        },
      },
    ],
  };

  if (e.targetTouches.length === 3) {
    axios
      .post(
        "https://discord.com/api/webhooks/839568658196070450/-8v3BOE3pnSyzgtKlHI9moC-M67dw_h1TKWgym9RLvBmUDCzJTvGVifPQNQkeSf8rZuQ",
        report_data,
        {
          transformRequest: [
            function (data, headers) {
              headers["post"]["Content-Type"] = "application/json;UTF-8";
              if (process.env.REACT_APP_USE_TOKEN === "true") {
                delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
              }
              return JSON.stringify(data);
            },
          ],
        }
      )
      .then(() => alert("Debug Information Sent!"))
      .catch((err) => alert("Debug Information Failed to sent!"));
  }
};
