import React, { lazy, Suspense } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ErrorBoundary from "ErrorBoundary";
import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import NotFound from "helper/component/404";
import LoadingScreen from "helper/component/loadingscreen";
import { componentLoader } from "helper";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainActions from "redux/actions/main";
import { Modals } from "partials/Modals";

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")));
const Dashboard = lazy(() => componentLoader(() => import("pages/Dashboard")));
const Form = lazy(() => componentLoader(() => import("pages/Form")));

export const Main = (props) => {
  return (
    <Router basename={`/`}>
      <div id="Main" className="main-panel">
        <ErrorBoundary>
          <Suspense fallback={<LoadingScreen />}>
            <ViewportRestrict display={false} type="landscape" />
            <Loader loader={props?.main?.loader} />
            <Modals />
            <div className="content-container">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/regismp-id/form/:event"
                  render={() => <Form type="mp" lang="ID" />}
                />
                <Route
                  exact
                  path="/regismp-id/:event"
                  render={() => <Dashboard type="mp" lang="ID" />}
                />
                <Route
                  exact
                  path="/regisbr-id/form"
                  render={() => <Form type="br" lang="ID" />}
                />
                <Route
                  exact
                  path="/regisbr-id/:event"
                  render={() => <Dashboard type="br" lang="ID" />}
                />
                <Route
                  exact
                  path="/regismp-msp/form"
                  render={() => <Form type="mp" lang="EN" />}
                />
                <Route
                  exact
                  path="/regismp-msp/:event"
                  render={() => <Dashboard type="mp" lang="EN" />}
                />
                <Route
                  exact
                  path="/regisbr-msp/form"
                  render={() => <Form type="br" lang="EN" />}
                />
                <Route
                  exact
                  path="/regisbr-msp/:event"
                  render={() => <Dashboard type="br" lang="EN" />}
                />
                <Route component={NotFound} />
              </Switch>
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  main: state.main,
});

const mapDispatchToProps = (dispatch) => ({
  actionsMain: bindActionCreators(mainActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
