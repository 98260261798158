const main_state = {
  login: false,
  loader: false,
  loader_hash: false,
  lang_json: false,
  popup_open: false,
  popup_type: false,
  tournament: false,
  delete_member_data: {
    id: false,
    idx: false,
  },

  // join data
  join_data: {
    team_name: "",
    registered: false,
    members: [
      {
        id: 0,
        t_open_id: "",
        is_leader: true,
        is_reserve: false,
        nickname: "",
      },
      {
        id: 0,
        t_open_id: "",
        is_leader: false,
        is_reserve: false,
        nickname: "",
      },
      {
        id: 0,
        t_open_id: "",
        is_leader: false,
        is_reserve: false,
        nickname: "",
      },
      {
        id: 0,
        t_open_id: "",
        is_leader: false,
        is_reserve: false,
        nickname: "",
      },
      {
        id: 0,
        t_open_id: "",
        is_leader: false,
        is_reserve: false,
        nickname: "",
      },
      {
        id: 0,
        t_open_id: "",
        is_leader: false,
        is_reserve: true,
        nickname: "",
      },
    ],
  },

  // form data
  form: {
    team_id: 0,
    team_name: "",
    members: [
      {
        id: 0,
        full_name: "",
        phone_number: "",
        t_open_id: "",
        nickname: "",
        discord_id: "",
        email: "",
        is_leader: true,
        is_reserve: false,
      },
      {
        id: 0,
        full_name: "",
        phone_number: "",
        t_open_id: "",
        nickname: "",
        discord_id: "",
        email: "",
        is_leader: false,
        is_reserve: false,
      },
      {
        id: 0,
        full_name: "",
        phone_number: "",
        t_open_id: "",
        nickname: "",
        discord_id: "",
        email: "",
        is_leader: false,
        is_reserve: false,
      },
      {
        id: 0,
        full_name: "",
        phone_number: "",
        t_open_id: "",
        nickname: "",
        discord_id: "",
        email: "",
        is_leader: false,
        is_reserve: false,
      },
      {
        id: 0,
        full_name: "",
        phone_number: "",
        t_open_id: "",
        nickname: "",
        discord_id: "",
        email: "",
        is_leader: false,
        is_reserve: false,
      },
      {
        id: 0,
        full_name: "",
        phone_number: "",
        t_open_id: "",
        nickname: "",
        discord_id: "",
        email: "",
        is_leader: false,
        is_reserve: true,
      },
    ],
  },
  error_form: {},
};

const main = (state = main_state, action) => {
  switch (action.type) {
    case "PUT_DATA":
      return { ...state, [action.key]: action.data };
    case "TOGGLE_LOADER":
      return { ...state, loader: action.data };
    case "TOGGLE_LOADER_HASH":
      return { ...state, loader_hash: action.data };
    case "TOGGLE_POPUP":
      return { ...state, popup_open: action.bool, popup_type: action.tipe };
    case "PUT_FORM":
      if (action.group === "members") {
        const members = [...state.form.members];
        members[action.idx] = {
          ...state.form.members[action.idx],
          [action.key]: action.data,
        };
        return {
          ...state,
          form: {
            ...state.form,
            members,
          },
        };
      }
      return {
        ...state,
        form: {
          ...state.form,
          [action.key]: action.data,
        },
      };
    default:
      return state;
  }
};

export default main;
